import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import Contact from '../components/contact'
import SEO from '../components/seo'

import styles from './blog.module.css'

const BlogList = (props) => {
  const { blogposts } = props.data

  return (
    <Layout location="/blog/">
      <SEO title="Blog" />
      <main className={styles.main}>
        {blogposts.edges.map((post, idx) => (
          <div className={styles.post}>
            <Link to={`/${post.node.slug}`}>
              <h2>{post.node.frontmatter.title}</h2>
            </Link>
            <div className={styles.postProps}>
              <span className={styles.author}>Dagmara Nowak</span>{' '}
              &nbsp;&nbsp;/&nbsp;&nbsp;
              {post.node.frontmatter.date}
            </div>
            <p>{post.node.excerpt}</p>
            <Link to={`/${post.node.slug}`}>
              <span className={styles.button}>Więcej</span>
            </Link>
          </div>
        ))}
        <Contact />
      </main>
    </Layout>
  )
}

export default BlogList
export const pageQuery = graphql`
  query blogposts {
    blogposts: allMdx(
      filter: { fileAbsolutePath: { regex: "//blog//" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          slug
          excerpt(pruneLength: 300)
          frontmatter {
            title
            date
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 350, maxHeight: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
